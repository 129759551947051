import {useABTest} from "@pg-mono/ab-testing";

import {abTestingInstance} from "../../ab_testing/ab_testing_instance";
import {OngoingABTests} from "../../ab_testing/ongoing_ab_tests";

const abTestProps = abTestingInstance.getABTestContainerProps(OngoingABTests.PLANER_VERIFICATION);

export const usePlansAbTest = () => {
    return useABTest(abTestProps);
};
